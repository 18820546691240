var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  /**
   * Encoding conversion table for JIS to UTF-8
   */
  var JIS_TO_UTF8_TABLE = null;
  exports = JIS_TO_UTF8_TABLE;
  return exports;
}