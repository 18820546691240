var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  /* eslint-disable key-spacing */
  /**
   * Katakana table
   */
  exports.HANKANA_TABLE = {
    12289: 65380,
    12290: 65377,
    12300: 65378,
    12301: 65379,
    12443: 65438,
    12444: 65439,
    12449: 65383,
    12450: 65393,
    12451: 65384,
    12452: 65394,
    12453: 65385,
    12454: 65395,
    12455: 65386,
    12456: 65396,
    12457: 65387,
    12458: 65397,
    12459: 65398,
    12461: 65399,
    12463: 65400,
    12465: 65401,
    12467: 65402,
    12469: 65403,
    12471: 65404,
    12473: 65405,
    12475: 65406,
    12477: 65407,
    12479: 65408,
    12481: 65409,
    12483: 65391,
    12484: 65410,
    12486: 65411,
    12488: 65412,
    12490: 65413,
    12491: 65414,
    12492: 65415,
    12493: 65416,
    12494: 65417,
    12495: 65418,
    12498: 65419,
    12501: 65420,
    12504: 65421,
    12507: 65422,
    12510: 65423,
    12511: 65424,
    12512: 65425,
    12513: 65426,
    12514: 65427,
    12515: 65388,
    12516: 65428,
    12517: 65389,
    12518: 65429,
    12519: 65390,
    12520: 65430,
    12521: 65431,
    12522: 65432,
    12523: 65433,
    12524: 65434,
    12525: 65435,
    12527: 65436,
    12530: 65382,
    12531: 65437,
    12539: 65381,
    12540: 65392
  };
  exports.HANKANA_SONANTS = {
    12532: 65395,
    12535: 65436,
    12538: 65382
  };
  exports.HANKANA_MARKS = [65438, 65439];

  /**
   * Zenkaku table [U+FF61] - [U+FF9F]
   */
  exports.ZENKANA_TABLE = [12290, 12300, 12301, 12289, 12539, 12530, 12449, 12451, 12453, 12455, 12457, 12515, 12517, 12519, 12483, 12540, 12450, 12452, 12454, 12456, 12458, 12459, 12461, 12463, 12465, 12467, 12469, 12471, 12473, 12475, 12477, 12479, 12481, 12484, 12486, 12488, 12490, 12491, 12492, 12493, 12494, 12495, 12498, 12501, 12504, 12507, 12510, 12511, 12512, 12513, 12514, 12516, 12518, 12520, 12521, 12522, 12523, 12524, 12525, 12527, 12531, 12443, 12444];
  return exports;
}