var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  /**
   * Binary (exe, images and so, etc.)
   *
   * Note:
   *   This function is not considered for Unicode
   */
  function isBINARY(data) {
    var i = 0;
    var len = data && data.length;
    var c;
    for (; i < len; i++) {
      c = data[i];
      if (c > 255) {
        return false;
      }
      if (c >= 0 && c <= 7 || c === 255) {
        return true;
      }
    }
    return false;
  }
  exports.isBINARY = isBINARY;

  /**
   * ASCII (ISO-646)
   */
  function isASCII(data) {
    var i = 0;
    var len = data && data.length;
    var b;
    for (; i < len; i++) {
      b = data[i];
      if (b > 255 || b >= 128 && b <= 255 || b === 27) {
        return false;
      }
    }
    return true;
  }
  exports.isASCII = isASCII;

  /**
   * ISO-2022-JP (JIS)
   *
   * RFC1468 Japanese Character Encoding for Internet Messages
   * RFC1554 ISO-2022-JP-2: Multilingual Extension of ISO-2022-JP
   * RFC2237 Japanese Character Encoding for Internet Messages
   */
  function isJIS(data) {
    var i = 0;
    var len = data && data.length;
    var b, esc1, esc2;
    for (; i < len; i++) {
      b = data[i];
      if (b > 255 || b >= 128 && b <= 255) {
        return false;
      }
      if (b === 27) {
        if (i + 2 >= len) {
          return false;
        }
        esc1 = data[i + 1];
        esc2 = data[i + 2];
        if (esc1 === 36) {
          if (esc2 === 40 ||
          // JIS X 0208-1990/2000/2004
          esc2 === 64 ||
          // JIS X 0208-1978
          esc2 === 66) {
            // JIS X 0208-1983
            return true;
          }
        } else if (esc1 === 38 &&
        // JIS X 0208-1990
        esc2 === 64) {
          return true;
        } else if (esc1 === 40) {
          if (esc2 === 66 ||
          // ASCII
          esc2 === 73 ||
          // JIS X 0201 Halfwidth Katakana
          esc2 === 74) {
            // JIS X 0201-1976 Roman set
            return true;
          }
        }
      }
    }
    return false;
  }
  exports.isJIS = isJIS;

  /**
   * EUC-JP
   */
  function isEUCJP(data) {
    var i = 0;
    var len = data && data.length;
    var b;
    for (; i < len; i++) {
      b = data[i];
      if (b < 128) {
        continue;
      }
      if (b > 255 || b < 142) {
        return false;
      }
      if (b === 142) {
        if (i + 1 >= len) {
          return false;
        }
        b = data[++i];
        if (b < 161 || 223 < b) {
          return false;
        }
      } else if (b === 143) {
        if (i + 2 >= len) {
          return false;
        }
        b = data[++i];
        if (b < 162 || 237 < b) {
          return false;
        }
        b = data[++i];
        if (b < 161 || 254 < b) {
          return false;
        }
      } else if (161 <= b && b <= 254) {
        if (i + 1 >= len) {
          return false;
        }
        b = data[++i];
        if (b < 161 || 254 < b) {
          return false;
        }
      } else {
        return false;
      }
    }
    return true;
  }
  exports.isEUCJP = isEUCJP;

  /**
   * Shift-JIS (SJIS)
   */
  function isSJIS(data) {
    var i = 0;
    var len = data && data.length;
    var b;
    while (i < len && data[i] > 128) {
      if (data[i++] > 255) {
        return false;
      }
    }
    for (; i < len; i++) {
      b = data[i];
      if (b <= 128 || 161 <= b && b <= 223) {
        continue;
      }
      if (b === 160 || b > 239 || i + 1 >= len) {
        return false;
      }
      b = data[++i];
      if (b < 64 || b === 127 || b > 252) {
        return false;
      }
    }
    return true;
  }
  exports.isSJIS = isSJIS;

  /**
   * UTF-8
   */
  function isUTF8(data) {
    var i = 0;
    var len = data && data.length;
    var b;
    for (; i < len; i++) {
      b = data[i];
      if (b > 255) {
        return false;
      }
      if (b === 9 || b === 10 || b === 13 || b >= 32 && b <= 126) {
        continue;
      }
      if (b >= 194 && b <= 223) {
        if (i + 1 >= len || data[i + 1] < 128 || data[i + 1] > 191) {
          return false;
        }
        i++;
      } else if (b === 224) {
        if (i + 2 >= len || data[i + 1] < 160 || data[i + 1] > 191 || data[i + 2] < 128 || data[i + 2] > 191) {
          return false;
        }
        i += 2;
      } else if (b >= 225 && b <= 236 || b === 238 || b === 239) {
        if (i + 2 >= len || data[i + 1] < 128 || data[i + 1] > 191 || data[i + 2] < 128 || data[i + 2] > 191) {
          return false;
        }
        i += 2;
      } else if (b === 237) {
        if (i + 2 >= len || data[i + 1] < 128 || data[i + 1] > 159 || data[i + 2] < 128 || data[i + 2] > 191) {
          return false;
        }
        i += 2;
      } else if (b === 240) {
        if (i + 3 >= len || data[i + 1] < 144 || data[i + 1] > 191 || data[i + 2] < 128 || data[i + 2] > 191 || data[i + 3] < 128 || data[i + 3] > 191) {
          return false;
        }
        i += 3;
      } else if (b >= 241 && b <= 243) {
        if (i + 3 >= len || data[i + 1] < 128 || data[i + 1] > 191 || data[i + 2] < 128 || data[i + 2] > 191 || data[i + 3] < 128 || data[i + 3] > 191) {
          return false;
        }
        i += 3;
      } else if (b === 244) {
        if (i + 3 >= len || data[i + 1] < 128 || data[i + 1] > 143 || data[i + 2] < 128 || data[i + 2] > 191 || data[i + 3] < 128 || data[i + 3] > 191) {
          return false;
        }
        i += 3;
      } else {
        return false;
      }
    }
    return true;
  }
  exports.isUTF8 = isUTF8;

  /**
   * UTF-16 (LE or BE)
   *
   * RFC2781: UTF-16, an encoding of ISO 10646
   *
   * @link http://www.ietf.org/rfc/rfc2781.txt
   */
  function isUTF16(data) {
    var i = 0;
    var len = data && data.length;
    var pos = null;
    var b1, b2, next, prev;
    if (len < 2) {
      if (data[0] > 255) {
        return false;
      }
    } else {
      b1 = data[0];
      b2 = data[1];
      if (b1 === 255 &&
      // BOM (little-endian)
      b2 === 254) {
        return true;
      }
      if (b1 === 254 &&
      // BOM (big-endian)
      b2 === 255) {
        return true;
      }
      for (; i < len; i++) {
        if (data[i] === 0) {
          pos = i;
          break;
        } else if (data[i] > 255) {
          return false;
        }
      }
      if (pos === null) {
        return false; // Non ASCII
      }

      next = data[pos + 1]; // BE
      if (next !== void 0 && next > 0 && next < 128) {
        return true;
      }
      prev = data[pos - 1]; // LE
      if (prev !== void 0 && prev > 0 && prev < 128) {
        return true;
      }
    }
    return false;
  }
  exports.isUTF16 = isUTF16;

  /**
   * UTF-16BE (big-endian)
   *
   * RFC 2781 4.3 Interpreting text labelled as UTF-16
   * Text labelled "UTF-16BE" can always be interpreted as being big-endian
   *  when BOM does not founds (SHOULD)
   *
   * @link http://www.ietf.org/rfc/rfc2781.txt
   */
  function isUTF16BE(data) {
    var i = 0;
    var len = data && data.length;
    var pos = null;
    var b1, b2;
    if (len < 2) {
      if (data[0] > 255) {
        return false;
      }
    } else {
      b1 = data[0];
      b2 = data[1];
      if (b1 === 254 &&
      // BOM
      b2 === 255) {
        return true;
      }
      for (; i < len; i++) {
        if (data[i] === 0) {
          pos = i;
          break;
        } else if (data[i] > 255) {
          return false;
        }
      }
      if (pos === null) {
        return false; // Non ASCII
      }

      if (pos % 2 === 0) {
        return true;
      }
    }
    return false;
  }
  exports.isUTF16BE = isUTF16BE;

  /**
   * UTF-16LE (little-endian)
   */
  function isUTF16LE(data) {
    var i = 0;
    var len = data && data.length;
    var pos = null;
    var b1, b2;
    if (len < 2) {
      if (data[0] > 255) {
        return false;
      }
    } else {
      b1 = data[0];
      b2 = data[1];
      if (b1 === 255 &&
      // BOM
      b2 === 254) {
        return true;
      }
      for (; i < len; i++) {
        if (data[i] === 0) {
          pos = i;
          break;
        } else if (data[i] > 255) {
          return false;
        }
      }
      if (pos === null) {
        return false; // Non ASCII
      }

      if (pos % 2 !== 0) {
        return true;
      }
    }
    return false;
  }
  exports.isUTF16LE = isUTF16LE;

  /**
   * UTF-32
   *
   * Unicode 3.2.0: Unicode Standard Annex #19
   *
   * @link http://www.iana.org/assignments/charset-reg/UTF-32
   * @link http://www.unicode.org/reports/tr19/tr19-9.html
   */
  function isUTF32(data) {
    var i = 0;
    var len = data && data.length;
    var pos = null;
    var b1, b2, b3, b4;
    var next, prev;
    if (len < 4) {
      for (; i < len; i++) {
        if (data[i] > 255) {
          return false;
        }
      }
    } else {
      b1 = data[0];
      b2 = data[1];
      b3 = data[2];
      b4 = data[3];
      if (b1 === 0 && b2 === 0 &&
      // BOM (big-endian)
      b3 === 254 && b4 === 255) {
        return true;
      }
      if (b1 === 255 && b2 === 254 &&
      // BOM (little-endian)
      b3 === 0 && b4 === 0) {
        return true;
      }
      for (; i < len; i++) {
        if (data[i] === 0 && data[i + 1] === 0 && data[i + 2] === 0) {
          pos = i;
          break;
        } else if (data[i] > 255) {
          return false;
        }
      }
      if (pos === null) {
        return false;
      }

      // The byte order should be the big-endian when BOM is not detected.
      next = data[pos + 3];
      if (next !== void 0 && next > 0 && next <= 127) {
        // big-endian
        return data[pos + 2] === 0 && data[pos + 1] === 0;
      }
      prev = data[pos - 1];
      if (prev !== void 0 && prev > 0 && prev <= 127) {
        // little-endian
        return data[pos + 1] === 0 && data[pos + 2] === 0;
      }
    }
    return false;
  }
  exports.isUTF32 = isUTF32;

  /**
   * JavaScript Unicode array
   */
  function isUNICODE(data) {
    var i = 0;
    var len = data && data.length;
    var c;
    for (; i < len; i++) {
      c = data[i];
      if (c < 0 || c > 1114111) {
        return false;
      }
    }
    return true;
  }
  exports.isUNICODE = isUNICODE;
  return exports;
}