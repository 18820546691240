import { dew as _utf8ToJisTableDew } from "./utf8-to-jis-table";
import { dew as _utf8ToJisx0212TableDew } from "./utf8-to-jisx0212-table";
import { dew as _jisToUtf8TableDew } from "./jis-to-utf8-table";
import { dew as _jisx0212ToUtf8TableDew } from "./jisx0212-to-utf8-table";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  exports.UTF8_TO_JIS_TABLE = _utf8ToJisTableDew();
  exports.UTF8_TO_JISX0212_TABLE = _utf8ToJisx0212TableDew();
  exports.JIS_TO_UTF8_TABLE = _jisToUtf8TableDew();
  exports.JISX0212_TO_UTF8_TABLE = _jisx0212ToUtf8TableDew();
  return exports;
}